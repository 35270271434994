import { ref, onMounted } from 'vue'

export function useWindowWidth() {
  const windowWidth = ref(window.innerWidth)
  const isWidgetExpanded = ref(!(window.innerWidth <= 575.98))
  const isExpandAllWidgets = ref(false)

  onMounted(() => {
    window.addEventListener('resize', () => {
      if (windowWidth.value !== window.innerWidth) {
        getWindowWidth()
      }
    })
    getWindowWidth()
  })

  function getWindowWidth() {
    windowWidth.value = window.innerWidth
    isWidgetExpanded.value = !(window.innerWidth <= 575.98)
    isExpandAllWidgets.value = false
  }

  return { windowWidth, isWidgetExpanded, isExpandAllWidgets, getWindowWidth }
}
