<template>
  <div class="col px-0" v-if="widgetData">
    <div class="widget-hover-content" v-if="hoverWidgetId === widgetData.widgetId">
      <div>
        <BIconArrowsMove class="m-2" scale="2" />
        <div class="d-none d-sm-block">
          <slot></slot>
        </div>
      </div>
    </div>
    <b-card
      no-body
      header-tag="header"
      header-class="d-flex justify-content-between align-items-center"
      footer-tag="footer"
      class="widgets"
      :style="{
        border: `2px solid ${widgetData.widgetColor}`,
      }"
    >
      <template #header>
        <div class="widget-logo-wraper">
          <div
            class="widget-logo"
            :style="{
              backgroundColor: widgetData.widgetColor,
            }"
          >
            <span v-if="widgetData.widgetLogo" v-html="widgetData.widgetLogo"></span>
            <img v-else :src="widgetData.widgetLogoBlobURI" alt="" />
          </div>
          <div class="widget-title px-3">
            <h3>{{ widgetData.widgetName }}</h3>
          </div>
        </div>
        <div class="d-flex d-sm-none pr-2" v-if="!hoverWidgetId">
          <b-button :title="isWidgetExpanded ? `Collapse` : `Expand`" variant="link" @click="isWidgetExpanded = !isWidgetExpanded">
            <PlusIcon class="plusicon" v-if="!isWidgetExpanded" />
            <MinusIcon class="plusicon" v-else />
          </b-button>
        </div>
      </template>
      <b-card-body v-if="isWidgetExpanded" :aria-hidden="isEditMode" tabindex="-1">
        <LoaderIcon v-if="isLoading" />
        <b-card-text v-else-if="error !== null && error.message">
          <error-handler :error="error" :contactUsUrl="contactUsUrl"></error-handler>
        </b-card-text>
        <b-card-text v-else :aria-hidden="isEditMode">
          <div v-html="widgetData.dashboardTxt" class="user-content theme-font-regular font-16"></div>
          <WidgetCategories v-if="widgetData.data" :widgetDetails="widgetData" :mode="mode" @showExpandedView="displayExpandedView" />
        </b-card-text>
      </b-card-body>
      <b-card-footer v-if="isWidgetExpanded" :aria-hidden="isEditMode" class="theme-font-regular font-16">
        <b-button :aria-haspopup="true" id="view-more-btn" href="#" block variant="primary" :disabled="disableViewMore" @click="displayExpandedView">
          <PlusIcon class="mt-n1 mr-2 plusicon" />{{ translateLanguage('Btn_Widget_View_more') }}
        </b-button>
      </b-card-footer>
    </b-card>
    <WidgetExpanedViewModal v-if="showExpandedView" :content="expandedViewContent" :mode="mode" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PlusIcon from '../../../assets/svg/plus.svg'
import MinusIcon from '../../../assets/svg/minus.svg'
import LoaderIcon from '../../../assets/svg/loader.svg'
import { useWindowWidth } from '../../../composables/useWindowWidth'
import { BIconArrowsMove } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'widgets',
  setup() {
    const { isWidgetExpanded } = useWindowWidth()

    return { isWidgetExpanded }
  },
  props: ['widgetDetails', 'hoverWidgetId', 'expandAllWidgets', 'isEditMode', 'isLoading', 'error', 'mode'],
  data() {
    return {
      widgetData: cloneDeep(this.widgetDetails),
      showExpandedView: false,
      expandedViewContent: null,
    }
  },
  components: {
    PlusIcon,
    MinusIcon,
    LoaderIcon,
    BIconArrowsMove,
    WidgetCategories: () => import('./WidgetCategories.vue'),
    WidgetExpanedViewModal: () => import('./WidgetExpanedViewModal.vue'),
    ErrorHandler: () => import('../../common/ErrorHandler.vue'),
  },
  computed: {
    disableViewMore() {
      return !!this.error || this.isLoading || this.isEditMode
    },
    ...mapState({
      contactUsUrl: (state) => state.shared.contactUsUrl,
      goToDashboardWidgetId: (state) => state.shared.goToDashboardWidgetId,
    }),
  },
  mounted() {
    this.widgetData = this.formatData()
  },
  methods: {
    formatData() {
      this.widgetData = cloneDeep(this.widgetDetails)
      let widgetResultProperty = null
      if (this.widgetData.widgetCategoryId === 4) {
        widgetResultProperty = 'locationWidgetResult'
      }
      if (this.widgetData.widgetCategoryId === 5) {
        widgetResultProperty = 'integrationWidgetResult'
      }
      if (widgetResultProperty) {
        if (this.widgetData.data) {
          this.widgetData.dashboardTxt = this.widgetData.data.dashboardTxt
          this.widgetData.expandedVwTxt = this.widgetData.data.expandedVwTxt
          if (this.widgetData.data.isFormat && this.widgetData.data[widgetResultProperty]) {
            this.widgetData.data[widgetResultProperty].formatContent.forEach((data) => {
              if (!data.hasOwnProperty('showLink') || ![0, 1, 2].includes(data.showLink)) {
                data.showLink = 0
              }
            })
          }
          this.widgetData.data.widgetResult = this.widgetData.data[widgetResultProperty]
        }
        return this.widgetData
      }
      return this.widgetData
    },
    disableWidgetContentFocus() {
      if (this.isEditMode) {
        const focusableElements = document.querySelectorAll(
          '.card .card-body a[href], .card .card-body button, .card .card-body input, .card .card-body textarea, .card .card-body select, .card .card-body details, .card .card-body [tabindex]:not([tabindex="-1"])'
        )

        focusableElements.forEach((el) => {
          el.setAttribute('tabIndex', '-1')
        })
      }
    },
    enableExpandedView() {
      if (this.goToDashboardWidgetId === this.widgetData.widgetId && !this.isLoading && !this.disableViewMore) {
        if (this.$el) {
          this.$el.scrollIntoView({ behavior: 'smooth' })
        }
        this.displayExpandedView()
      }
    },
    displayExpandedView() {
      this.showExpandedView = false
      this.expandedViewContent = this.widgetData
      this.$nextTick(() => {
        this.showExpandedView = true
      })
      this.$store.commit('shared/setGoToDashboardWidgetId', -1)
    },
  },
  watch: {
    widgetDetails(newValue, oldValue) {
      this.enableExpandedView()
      this.disableWidgetContentFocus()
      this.widgetData = this.formatData()
      if (this.showExpandedView) {
        this.expandedViewContent = this.widgetData
      }
      if (this.error) {
        this.showExpandedView = false
      }
    },
    goToDashboardWidgetId(newValue, oldValue) {
      this.enableExpandedView()
    },
    expandAllWidgets(newValue, oldValue) {
      this.isWidgetExpanded = newValue
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../../variables.scss';
.widgets {
  box-shadow: 8px 8px 8px rgb(0 0 0 / 5%);

  .card-header,
  .card-footer {
    background-color: #fff;
    padding: 0;
    border: none;
  }

  .card-body {
    margin-right: 0.5rem;
    min-height: 220px;
    max-height: 220px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &:hover {
      scrollbar-color: auto;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .card-body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .card-body:hover::-webkit-scrollbar-track {
    background: #ececec;
    border-radius: 25rem;
  }

  .card-body:hover::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 25rem;
  }

  .card-footer {
    border-top: 2px solid #f0f0f0;

    .btn {
      background: #fff;
      color: #000;
      font-family: $THEMEFONTBOLD;
      font-size: 15px;
      border: none;
    }

    .btn:hover {
      filter: drop-shadow(2px 4px 6px black);
    }
  }
}
.widget-logo-wraper {
  display: flex;

  .widget-logo {
    width: 64px;
    min-width: 64px;
    align-items: center;
    font-weight: 600;
    height: 60px;
    display: grid;
    justify-content: center;
    border-radius: 5px 0px;

    svg,
    img {
      width: 30px;
      height: 30px;
    }
  }

  .widget-title {
    word-break: break-word;
    display: flex;
    align-items: center;
    font-family: $THEMEFONTBOLD;
    font-size: 17px;
  }
}
.widget-hover-content {
  color: #fff;
  padding: 0px 35px;
  position: absolute;
  border-top-left-radius: 5px;
  height: 100%;
  z-index: 15;
  text-align: center;
  background: #000000c4;
  margin: 0 auto;
  cursor: grab;
  align-items: center;
  display: grid;
  width: 100%;
}
.widget-hover-content:active {
  cursor: grabbing;
}
.widget-title h3 {
  font-size: 17px;
}
</style>
